import { Stack } from '@dlpco/fluid-layout'
import { Display, Heading } from '@dlpco/ginga-stone'
import { NotFoundIllustration } from 'design-elements/shared/illustrations'

import { Box } from '~/domains/platform/design-system'
import { withPageConfig } from '~/domains/platform/infra/page-enhancers/with-page-config'
import { ErrorContainer } from '~/ui/containers'

function Custom404() {
  return (
    <ErrorContainer
      illustration={<NotFoundIllustration />}
      style={{ background: 'none' }}
      title={'Erro 404'}
      redirectHome
    >
      <Stack space="3rem">
        <Box maxWidth="60ch">
          <Display>Oops, parece que esse lugar não existe.</Display>
        </Box>
        <Heading size="large">Volte para o inicio e tente novamente</Heading>
      </Stack>
    </ErrorContainer>
  )
}

export default withPageConfig(Custom404, {
  entityKYC: false,
  navigation: 'default',
  exMachinaOptIns: ['banking']
})
